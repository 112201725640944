import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductsService } from 'src/app/shared/services/products.service';
import { CrudService } from 'src/app/shared/services/firebase/crud.service';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  public products: any[] = [];
  public product: any[] = [];
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  dtTrigger: Subject<any> = new Subject();

  constructor(
    private router: Router,
    private productsService:ProductsService,
    private crudService:CrudService) 
  {
    

    
  }


  ngOnInit() {

    this.get_products();
    // this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   language: {
    //     "processing": "Procesando",
    //     "lengthMenu": "Mostrar _MENU_ registros",
    //     "emptyTable": "Ningún dato disponible en esta tabla",
    //     "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
    //     "infoEmpty": "Mostrando 0 registros del 0 de entradas",
    //     "infoFiltered": "(filtrado de un total de _MAX_ registros)",
    //     "search": "Buscar",
    //     "infoPostFix": "",
    //     "url": "",
    //     "zeroRecords": "No se encontraron resultados",
    //     "loadingRecords": "Cargando...",
    //     "paginate":{
    //       "first": "Primero",
    //       "last": "Último",
    //       "next": "Siguiente",
    //       "previous": "Anterior"
    //     },
    //     "aria": {
    //       "sortAscending": ": Activar para ordenar la columna de manera ascendente",
    //       "sortDescending": ": Activar para ordenar la columna de manera descendente"
    //     }

        
        
    //   }
    // };

    
  }


  get_products(){
    this.crudService.getAll("products").subscribe(data => {
      this.products = data;
      window.setTimeout( function (){ $('#products-index').DataTable(
        {
          pagingType: 'full_numbers',
          language: {
            "processing": "Procesando",
            "lengthMenu": "Mostrar _MENU_ registros",
            "emptyTable": "Ningún dato disponible en esta tabla",
            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
            "infoEmpty": "Mostrando 0 registros del 0 de entradas",
            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
            "search": "Buscar",
            "infoPostFix": "",
            "url": "",
            "zeroRecords": "No se encontraron resultados",
            "loadingRecords": "Cargando...",
            "paginate":{
              "first": "Primero",
              "last": "Último",
              "next": "Siguiente",
              "previous": "Anterior"
            },
            "aria": {
              "sortAscending": ": Activar para ordenar la columna de manera ascendente",
              "sortDescending": ": Activar para ordenar la columna de manera descendente"
            }
    
            
            
          }
        }
      )}, 1000);
      //$('#products-index').DataTable(this.dtOptions);
      //$('.dataTable').DataTable().ajax.reload();
    });

  }


  delete_product(id){
    if(confirm("Esta seguro que desea eliminar")) {
      this.crudService.delete('products',id).subscribe(
        data => { this.product = data;
                  this.router.navigate(['./products/']);
                  document.querySelector('#tr_product_'+id).remove();
                },
        error => {alert("No se puede eliminar Plan, el motivo es que existen usuarios suscritos a este plan")}
      );
    }
  }

  public jsonValue(): string  {
    return  JSON.stringify(this.products, null,4);
  }


}
