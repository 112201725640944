import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Select2OptionData } from 'ng-select2';
import { ProductsService } from 'src/app/shared/services/products.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss']
})
export class NewComponent implements OnInit {
  public ProductForm: FormGroup;
  public product: any[] = [];
  public images_url: any[] = [];
  public categories: Array<Select2OptionData>;
  public base64Image: Array<string> = null;
  config_content: any = {};
  config_desc: any = {};
  //public fileReader: FileReader;


  constructor(
    private router: Router,
    public productsService: ProductsService,
    private fb: FormBuilder)
   { 

    this.productsService.getAllCategories().subscribe(data => {
      let arrCategories = [];
      for (let i = 0; i < data.length; i++) {
        arrCategories.push({id: data[i].id.toString(), text: data[i].name})
      }
      this.categories = arrCategories;
    });


    this.ProductForm = fb.group({
      plan_name: ['', [Validators.required]],
      plan_content: ['', Validators.required],
      plan_description: ['', Validators.required],
      plan_cost: ['', [Validators.required]],
      category_id: ['', [Validators.required]],
      frecuency: ['', [Validators.required]],
      pictures: ['', [Validators.required]]
    });

    this.base64Image = new Array<string>()
   }


   
  ngOnInit(){
    this.config_content = {
      toolbar: [
        ['uiColor', '#dddddd'],
        ['Maximize'],
        ['Format', 'FontSize'],
        ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat'],
        ['TextColor', 'BGColor'],
        ['NumberedList', 'BulletedList'],
        ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
        ['Cut', 'Copy'],
        ['Undo', 'Redo']
      ],
      height: 100
      
    };

    this.config_desc = {
      height: 300
    };
    
  }

  handleFileInput(files: FileList) {
    for(let i = 0; i < files.length; i++){
      let fileReader = new FileReader();
      fileReader.readAsDataURL(files.item(i));
      fileReader.onloadend = (e) => {
        this.base64Image.push(fileReader.result.toString())
        this.images_url.push(fileReader.result)
        console.log("file: "+i+" - "+this.base64Image[i]);
      }
    }
  }



  parse_img(img): string{
    if(img.indexOf('data') >= 0){
      return img;
    }else{
      return  environment.url_api + img;
    }
  }




  new_product(){

    console.log("File: "+this.base64Image);
    
    var product = {
      product: {
      "name": this.ProductForm.value['plan_name'], 
      "content": this.ProductForm.value['plan_content'],
      "description": this.ProductForm.value['plan_description'],
      "price": this.ProductForm.value['plan_cost'],
      "category_id": this.ProductForm.value['category_id'],
      "frecuency": this.ProductForm.value['frecuency'],
      "images": JSON.stringify(this.base64Image)
      }
    }
    this.productsService.new_product(product).subscribe(
      data => {this.product = data;
      this.router.navigate(['./products/']);
    });
  }

}
