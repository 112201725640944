import { AuthService } from './../../../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute , Params} from '@angular/router';
import { User } from 'src/app/shared/models/user';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  public user: any;

  constructor(
    private activeRoute: ActivatedRoute,
    private auth: AuthService,
    private router: Router,
  ) 
  { 
    this.user = AuthService.get_user();
  }

  ngOnInit() {
    //this.user = this.auth.getAutenticatedUser();
  }

  logout(){
    //this.auth.logOutUser();
    this.auth.DestroySesion();
    this.router.navigate(['/auth/login']);
  }

  parse_avatar_img(avatar_url): string{
    if(avatar_url){

    return  environment.url_api + avatar_url;
    }
    else{
      return '../assets/images/user/2.png'
    }
  }

}
