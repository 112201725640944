<div class="container-fluid">
    <div class="page-title">
      <div class="row">
        <div class="col-6">
          <h3>Reporte de pagos</h3>
        </div>
        <div class="col-6">
          <ol class="breadcrumb">
            <!-- <li class="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li> -->
            <li class="breadcrumb-item"><a [routerLink]="['/customer/']"><i class="fa fa-home"></i></a></li>
            <li class="breadcrumb-item active"> Historial Clientes</li>
          </ol>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="col-sm-12">
        <div class="card">
           <div class="card-header">

            <a class="btn btn-primary" type="button" (click)="generateReport()">
              Descargar Reporte Pago Clientes
            </a>

             <!-- <div class="col-sm-6" >             
                <a class="btn btn-primary" [routerLink]="'/products/new'" >
                  <i class="icon-plus"></i>Nuevo Plan
                </a>
            </div> -->

           </div>

           

           <div class="card-body">
             <div class="table-responsive">
               <table class="display" id="cust-index">
                 <thead>
                   <tr>
                     <th>Nombre del cliente</th>
                     <th>Acciones</th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr *ngFor="let customer of customers" >
                     <td>{{customer.full_name}}</td>
                     <td>
                      <a class="btn btn-primary" [routerLink]="['/customer/payments/',customer.id]" >
                        <i class="icon-pencil-alt"></i> Historial pagos
                      </a>
                      &nbsp;
                      <a class="btn btn-primary" type="button" (click)="generateReportCustomer(customer.id)">
                        Descargar Reporte Pago Cliente 
                      </a>
                 
                   </tr>        
                 </tbody>
               </table>
             </div>
           </div>
         </div>
       </div>
   </div>
