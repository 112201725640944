import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudService } from 'src/app/shared/services/firebase/crud.service';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss']
})
export class EditCategoryComponent implements OnInit {
  public CategoryForm: FormGroup;
  public params: any;
  public category: any = [];

  constructor(
    private router: Router,
    private route_params:ActivatedRoute,
    public crudService:CrudService,
    private fb: FormBuilder
  ) { 
    this.route_params.params.subscribe(params => {
      this.params = params;
    });

    this.crudService.get('categories',this.params['id']).subscribe(data => {
      this.category = data;
    });

    this.CategoryForm = fb.group({
      category_name: ['', [Validators.required]],
      frecuency: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  edit_category(){
    var category = {
      "id": this.params['id'],
      "name": this.CategoryForm.value['category_name'], 
      "frecuency": this.CategoryForm.value['frecuency']
      
    }
    this.crudService.update('categories',category).subscribe(
      data => {this.category = data;
      this.router.navigate(['./categories/']);
    });
  }

}
