<div class="container-fluid">
    <div class="page-title">
      <div class="row">
        <!--<div class="col-6">
          <h3>Crear Plan</h3>
        </div>-->
        <div class="col-6">
          <ol class="breadcrumb">
            <!-- <li class="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li> -->
            <li class="breadcrumb-item"><a [routerLink]="['/customer/']"><i class="fa fa-home"></i></a></li>
            <li class="breadcrumb-item active"> Categoría</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h5>Crear Categoría</h5>
          </div>
          <div class="card-body add-post">
            <form class="row needs-validation" [formGroup]="CategoryForm">
              <div class="col-sm-12">
                <div class="form-group">
                  <input class="form-control" id="validationCustom01" type="text" placeholder="Nombre de categoría" formControlName="category_name">
                  <div class="valid-feedback">Looks good!</div>
                </div>

                <div class="btn-showcase">
                  <button class="btn btn-primary"
                      [class.loader--text]="categoriesService.showLoader"
                      [disabled]="categoriesService.showLoader"
                      (click)="new_category()"
                      type="button"><span>{{ categoriesService.showLoader ? '' : 'Guardar' }}</span>
                  </button>
                  <a class="btn btn-primary" [routerLink]="'/categories'" >
                    Cancelar
                  </a>
                </div>
              </div>
            </form>
            
            <!--<div class="btn-showcase">
              <button class="btn btn-primary" type="submit">Guardar</button>
              <button class="btn btn-primary" type="submit">Cancelar</button>

            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->

