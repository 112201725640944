import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CategoriesService } from 'src/app/shared/services/categories.service';

@Component({
  selector: 'app-new-categories',
  templateUrl: './new-categories.component.html',
  styleUrls: ['./new-categories.component.scss']
})
export class NewCategoriesComponent implements OnInit {
  public CategoryForm: FormGroup;
  public category: any[] = [];

  constructor(
    private router: Router,
    public categoriesService:CategoriesService,
    private fb: FormBuilder) 
    { 
    this.CategoryForm = fb.group({
      category_name: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
  }

  new_category(){
    var data = {
      category: {
      "name": this.CategoryForm.value['category_name']
      }
    }
    this.categoriesService.new_category(data).subscribe(
      data => {this.category = data;
      this.router.navigate(['./categories/']);
    });
  }

}
