import { HttpHeaders } from '@angular/common/http';
import { auth } from 'firebase/app';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AuthService } from '../../shared/services/auth.service';
import { UsersService } from 'src/app/shared/services/users.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public show: boolean = false;
  public loginForm: FormGroup;
  public errorMessage: any;
  public user: any;
  public alert_type: string = 'success';
  public alert_message: string = null;


  constructor(
    private router: Router,
    public authService: AuthService,
     private fb: FormBuilder,
     private route: ActivatedRoute,
     private auth: AuthService,
     //private userService: UsersService,
    ) 
    {
      AuthService.set_sesion(null);
      
      this.loginForm = fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required],
        remember: ['', ]
      });
  }

  ngOnInit() {

    if(localStorage.getItem('alert_message') != null){
      this.alert_message = localStorage.getItem('alert_message');
      localStorage.removeItem('alert_message');
    }

    if(localStorage.getItem('alert_type') != null){
      this.alert_type = localStorage.getItem('alert_type');
      localStorage.removeItem('alert_type');
    };
    
  }

  showPassword() {
    this.show = !this.show;
  }
  
  // Login With Google
  loginGoogle() {
   // this.authService.GoogleAuth();
  }

  // Login With Twitter
  loginTwitter(): void {
   // this.authService.signInTwitter();
  }

  // Login With Facebook
  loginFacebook() {
   // this.authService.signInFacebok();
  }

  // Simple Login
  login(l) {

    var user = {
      user: {
      "email": this.loginForm.value['email'], 
      "password": this.loginForm.value['password']    
      }
    }
    this.auth.loginUser(user).subscribe((result)=>{
      if(result.body.id){
        let res = this.extractDataAndSetAuthHeader(result);
      }else{
        this.alert_type = 'danger';
        this.alert_message = 'Email o contraseña incorrecta.';
      }
    });  

  }



  // Set auth header if available.
  // If not available - user is not logged in. Then 
  // we also have to remove the token from localStorage
  extractDataAndSetAuthHeader(res: Response) {
    console.log("get_sesion"+JSON.stringify(AuthService.get_sesion().body.id));
    //if(AuthService.get_sesion().body.id)
    if (res.headers.has("Authorization"))
    {
        this.user = res['body'];
        AuthService.set_user(this.user);
        const jwt = res.headers.get('Authorization').split('Bearer ')[1];
        console.log("jwt:", jwt)
        let token = res.headers.get("Authorization");

        localStorage.setItem('id_token', token);
        localStorage.setItem('token', jwt);
        if(this.loginForm.value['remember'] == true){
          localStorage.setItem('remember', '1');
        }else{
          localStorage.removeItem('remember');
        }
        localStorage.setItem('user', JSON.stringify(res));
        
        this.router.navigate(['./customer/']);
    }
    else
    {
        // If no token is sent, remove it
        localStorage.removeItem('user');
        localStorage.removeItem('id_token');
        localStorage.removeItem('token');
        localStorage.removeItem('remember');
    }
  }



}
