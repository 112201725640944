<div class="container-fluid">
    <div class="page-title">
      <div class="row">
        <div class="col-6">
          <h3>Registro de Categorías</h3>
        </div>
        <div class="col-6">
          <ol class="breadcrumb">
            <!-- <li class="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li> -->
            <li class="breadcrumb-item"><a [routerLink]="['/customer/']"><i class="fa fa-home"></i></a></li>
            <li class="breadcrumb-item active"> Categorías</li>
          </ol>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="col-sm-12">
        <div class="card">
           <div class="card-header">

             <div class="col-sm-6" >             
                <a class="btn btn-primary" [routerLink]="'/categories/new'" >
                  <i class="icon-plus"></i>Nuevo Categoría
                </a>
            </div>

           </div>

           

           <div class="card-body">
             <div class="table-responsive">
               <table class="display" id="cat-index">
                 <thead>
                   <tr>
                     <th>Nombre de Categoría</th>
                     <th>Acciones</th>
                   </tr>
                 </thead>
                 <tbody>
                   <tr *ngFor="let column of categories" id="tr_category_{{column.id}}">
                     <td>{{column.name}}</td>
                     <td>
                      <a class="btn btn-primary" [routerLink]="['/categories/edit-category/',column.id]" >
                        <i class="icon-pencil-alt"></i> Editar
                      </a>&nbsp;&nbsp;
                       <!--<button class="btn btn-primary" data-original-title="" title=""><i class="icon-pencil-alt"></i> Editar</button>-->
                      <button class="btn btn-secondary" data-original-title="" title="" (click)="delete_category(column.id)"><i class="icon-trash"></i> Eliminar</button>
                      </td>
                 
                   </tr>        
                 </tbody>
               </table>
             </div>
           </div>
         </div>
       </div>
   </div>
   
