<div class="container-fluid">
    <div class="page-title">
      <div class="row">
        <div class="col-6">
          <h3></h3>
        </div>
        <div class="col-6">
          <ol class="breadcrumb">
            <!-- <li class="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li> -->
            <li class="breadcrumb-item"><a [routerLink]="['/customer/']"><i class="fa fa-home"></i></a></li>
            <li class="breadcrumb-item active"> Plan</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h5>Crear Plan</h5>
          </div>
          <div class="card-body add-post">
            <form accept-charset="UTF-8" class="row needs-validation" [formGroup]="ProductForm" enctype="multipart/form-data" method="post">
              <div class="col-sm-12">
                <div class="form-group">
                  <input class="form-control" id="validationCustom01" type="text" placeholder="Nombre del Plan" formControlName="plan_name">
                  <div class="valid-feedback">Looks good!</div>
                </div>
               
                <div class="form-group">
                  <div class="col-form-label">Categoria:
                    <ng-select2 class="js-example-placeholder-multiple col-sm-12" [data]="categories" formControlName="category_id"[placeholder]="'Seleccione una opción...'" width="100%" style="width: 100%;"></ng-select2>
                  </div>
                </div>

                

                <div class="form-group">
                  <div class="col-form-label">Periodo:
                    <!-- <input class="form-control" id="validationCustom01" type="text" placeholder="Periodo" formControlName="frecuency"> -->
                    <select class="form-control" id="validationCustom01" formControlName = "frecuency">
                      <option>Seleccione una opción</option>
                      <option value="Mensual">Mensual</option>
                      <option value="Anual">Anual</option>
                  </select>
                  </div>
                </div>


                <div class="form-group">
                  <input class="form-control" id="validationCustom01" type="text" placeholder="Costo Mensual"  formControlName="plan_cost">
                </div>

                <div class="form-group">
                  <label>Contenido Plan:</label>
                  <ckeditor [config]="config_content"
                            [readonly]="false"
                            formControlName="plan_content"
                            >
                  </ckeditor>
                </div>

                <div class="form-group">
                  <label>Descripción Plan:</label>
                  <ckeditor [config]="config_desc"
                            [readonly]="false"
                            formControlName="plan_description"
                            >
                  </ckeditor>

                </div>

                <div class="form-group">
                  <div class="dropzone" id="singleFileUpload">
                    <div class="m-0 dz-message needsclick"><i class="icon-cloud-up"></i><br><br>
                      <input type="file" id="file" multiple="true" (change)="handleFileInput($event.target.files)" formControlName="pictures">
                      <img *ngFor="let img of images_url; let i = index" class="img-100 rounded-circle" src="{{parse_img(img)}}" alt="" height="100"> 
                    </div>
                  </div>
                  
                </div>

                <div class="btn-showcase">
                  <button class="btn btn-primary"
                      [class.loader--text]="productsService.showLoader"
                      [disabled]="productsService.showLoader"
                      (click)="new_product()"
                      type="button"><span>{{ productsService.showLoader ? '' : 'Guardar' }}</span>
                  </button>
                  <a class="btn btn-primary" [routerLink]="'/products'" >
                    Cancelar
                  </a>
                </div>
              </div>
            </form>
            
            <!--<div class="btn-showcase">
              <button class="btn btn-primary" type="submit">Guardar</button>
              <button class="btn btn-primary" type="submit">Cancelar</button>

            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
