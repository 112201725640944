import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from './../../../environments/environment';
import { Observable } from "rxjs/Observable";
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  public showLoader: boolean = false;

  constructor(private http: HttpClient) { 

  }

  public new_category(data):Observable<any> {
    return this.http.post(`${environment.url_api}/categories.json`, data)
  }

  public delete_category(data):Observable<any> {
    return this.http.delete(`${environment.url_api}/categories/${data}.json`)
  }
}
