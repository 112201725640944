import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CrudService } from 'src/app/shared/services/firebase/crud.service';
import { ReportService } from 'src/app/shared/services/firebase/report.service';
import { environment } from 'src/environments/environment';
import { CustomDatePipe } from 'src/app/shared/date/custom.datepipe.ts ';


@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  public current_user: any = [];
  public user: any = [];
  public environment: any;
  public params: any;
  public report_url:string;


  constructor(
    private router: Router,
    private crudService:CrudService,
    private route_params:ActivatedRoute,
    private reportService:ReportService
  ) 
  {
    this.current_user = AuthService.get_user(); 

    this.route_params.params.subscribe(params => {
      this.params = params;
      CrudService.set_customer(this.params['id']);
    });
     

   
    
   }

   
  generateReport(): void {
    this.reportService.report('payments.xlsx');
  }

  ngOnInit() {
    this.get_users();
    // this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   language: {
    //     "processing": "Procesando",
    //     "lengthMenu": "Mostrar _MENU_ registros",
    //     "emptyTable": "Ningún dato disponible en esta tabla",
    //     "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
    //     "infoEmpty": "Mostrando 0 registros del 0 de entradas",
    //     "infoFiltered": "(filtrado de un total de _MAX_ registros)",
    //     "search": "Buscar",
    //     "infoPostFix": "",
    //     "url": "",
    //     "zeroRecords": "No se encontraron resultados",
    //     "loadingRecords": "Cargando...",
    //     "paginate":{
    //       "first": "Primero",
    //       "last": "Último",
    //       "next": "Siguiente",
    //       "previous": "Anterior"
    //     },
    //     "aria": {
    //       "sortAscending": ": Activar para ordenar la columna de manera ascendente",
    //       "sortDescending": ": Activar para ordenar la columna de manera descendente"
    //     }

        
        
    //   }
    // };
  }

  get_users(){
    this.crudService.get('users',this.params['id']).subscribe(data => {
      this.user = data;
      window.setTimeout( function (){ $('#basic-1').DataTable(
        {
          pagingType: 'full_numbers',
          language: {
            "processing": "Procesando",
            "lengthMenu": "Mostrar _MENU_ registros",
            "emptyTable": "Ningún dato disponible en esta tabla",
            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
            "infoEmpty": "Mostrando 0 registros del 0 de entradas",
            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
            "search": "Buscar",
            "infoPostFix": "",
            "url": "",
            "zeroRecords": "No se encontraron resultados",
            "loadingRecords": "Cargando...",
            "paginate":{
              "first": "Primero",
              "last": "Último",
              "next": "Siguiente",
              "previous": "Anterior"
            },
            "aria": {
              "sortAscending": ": Activar para ordenar la columna de manera ascendente",
              "sortDescending": ": Activar para ordenar la columna de manera descendente"
            }
    
            
            
          }
        }
      )}, 1000);
    });

  }

}
