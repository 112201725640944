import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from './../../../environments/environment';
import { Observable } from "rxjs/Observable";
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { tokenize } from "@angular/compiler/src/ml_parser/lexer";
import { AuthInterceptorService } from "./auth-interceptor.service";

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  public showLoader: boolean = false;

  constructor(
    private api :ApiService,
    private http: HttpClient,
    private interceptor:AuthInterceptorService
  ) { }

  getProducts(){
    return  this.api.postResponse('/products.json',true, {});
  }



  public getAllCategories():Observable<any> {  
    return this.http.get(`${environment.url_api}/categories.json`, {responseType:'json'})
    //.then((data) => {console.log("Success getAllProducts"+JSON.stringify(data))}).catch(err=> {console.log(err)});
    
  }


  public getAllProducts():Observable<any> {  
    return this.http.get(`${environment.url_api}/products.json`, {responseType:'json'})
    //.then((data) => {console.log("Success getAllProducts"+JSON.stringify(data))}).catch(err=> {console.log(err)});
    
  }

  public new_product(data):Observable<any> {
    return this.http.post(`${environment.url_api}/products.json`, data)
  }
}
