import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CrudService } from 'src/app/shared/services/firebase/crud.service';
import { ReportService } from 'src/app/shared/services/firebase/report.service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-all-users',
  templateUrl: './all-users.component.html',
  styleUrls: ['./all-users.component.scss']
})
export class AllUsersComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  public customers: any[] = [];

  constructor(
    private router: Router,
    private crudService:CrudService,
    private reportService:ReportService
  ) 
  {
    
   }

  ngOnInit(){
    this.get_users();

    //this.report_url  = environment.url_api + '/users/historial_subscriptions.xlsx'
    // this.dtOptions = {
    //   pagingType: 'full_numbers',
    //   language: {
    //     "processing": "Procesando",
    //     "lengthMenu": "Mostrar _MENU_ registros",
    //     "emptyTable": "Ningún dato disponible en esta tabla",
    //     "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
    //     "infoEmpty": "Mostrando 0 registros del 0 de entradas",
    //     "infoFiltered": "(filtrado de un total de _MAX_ registros)",
    //     "search": "Buscar",
    //     "infoPostFix": "",
    //     "url": "",
    //     "zeroRecords": "No se encontraron resultados",
    //     "loadingRecords": "Cargando...",
    //     "paginate":{
    //       "first": "Primero",
    //       "last": "Último",
    //       "next": "Siguiente",
    //       "previous": "Anterior"
    //     },
    //     "aria": {
    //       "sortAscending": ": Activar para ordenar la columna de manera ascendente",
    //       "sortDescending": ": Activar para ordenar la columna de manera descendente"
    //     }

        
        
    //   }
    // };
  }

  generateReportCustomer(customer_id): void {
    this.reportService.report('payments.xlsx?id='+customer_id);
  }

  generateReport(): void {
    this.reportService.report('historial_subscriptions.xlsx');
  }

  

  get_users(){
    this.crudService.getAll("users").subscribe(data => {
      this.customers = data;
      window.setTimeout( function (){ $('#cust-index').DataTable(
        {
          pagingType: 'full_numbers',
          language: {
            "processing": "Procesando",
            "lengthMenu": "Mostrar _MENU_ registros",
            "emptyTable": "Ningún dato disponible en esta tabla",
            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
            "infoEmpty": "Mostrando 0 registros del 0 de entradas",
            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
            "search": "Buscar",
            "infoPostFix": "",
            "url": "",
            "zeroRecords": "No se encontraron resultados",
            "loadingRecords": "Cargando...",
            "paginate":{
              "first": "Primero",
              "last": "Último",
              "next": "Siguiente",
              "previous": "Anterior"
            },
            "aria": {
              "sortAscending": ": Activar para ordenar la columna de manera ascendente",
              "sortDescending": ": Activar para ordenar la columna de manera descendente"
            }
    
            
            
          }
        }
      )}, 1000);
    });
  }

}
