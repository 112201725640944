<div class="container-fluid p-0"> 
  <div class="row">
    <div class="col-12">     
      <div class="login-card">
        <div>
          <div>
            <!-- <a class="logo"  routerLink='/'>
              <img class="img-fluid for-light" src="assets/images/logo/rackup_negro.png" alt="looginpage">
              <img class="img-fluid for-dark" src="assets/images/logo/rackup_blanco.png" alt="looginpage">
            </a> -->
          </div>
          <div class="login-main"> 
            <form [formGroup]="form" (ngSubmit) = "registerUser($event)" class="theme-form">
            
              <h4>Crear tu cuenta</h4>
              <p>Favor de introducir su información para registrarse</p>
              <div class="form-group">
                <label class="col-form-label pt-0">Tu Nombre</label>
                <div class="form-row">
                  <div class="col-6">
                    <input class="form-control" type="text" placeholder="Nombre" formControlName="name" >
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="form-row">
                  <div class="col-6">
                    <input class="form-control" type="text" placeholder="Apellido paterno" formControlName="lastname" > 
                  </div>
                  <div class="col-6">
                    <input class="form-control" type="text" placeholder="Apellido materno" formControlName="second_lastname" > 
                  </div>
                  <div *ngIf="registerFormSubmitted && (rf.name.invalid || rf.name.errors?.required)"
                  class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Su nombre es requerido.</div>
                  <div *ngIf="registerFormSubmitted && (rf.lastname.invalid || rf.lastname.errors?.required)"
                  class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Sus apellidos son requerido.</div>

                </div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Correo electrónico</label>
                <input class="form-control" type="email" required="" placeholder="app@correoelectronico.com"  formControlName = "email" >
                <div *ngIf="registerFormSubmitted && (rf.email.errors?.required)"
                class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Su Email es requerido</div>
              <div *ngIf="registerFormSubmitted &&  rf.email.errors?.email" class="help-block mt-1 text-danger"> <i
                  class="ft-alert-circle align-middle"></i> El Email debe de ser un email válido</div>

              </div>
              <div class="form-group">
                <label class="col-form-label">Contraseña</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" name="login[password]" required="" placeholder="*********" formControlName = "password" >
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
                <div *ngIf="registerFormSubmitted && (rf.password.invalid || rf.password.errors?.required)"
                class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> El password es requerido.</div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Fecha de cumpleaños</label>
                <input class="form-control" type="date" required="" placeholder="dd/mm/yyyy" formControlName = "birthday">
                <div *ngIf="registerFormSubmitted && (rf.birthday.invalid || rf.birthday.errors?.required)"
                class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Su fecha de nacimiento es requerida.</div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Objetivo Rackup</label>
                <select class="form-control digits" id="exampleFormControlSelect9" formControlName = "objetivo">
                  <option>Bajar de peso</option>
                  <option>Aumentar mi masa muscular</option>
                  <option>Mantenimiento</option>
                  <option>Mejorar mi condición física</option>
              </select>
              <div *ngIf="registerFormSubmitted && (rf.objetivo.invalid || rf.objetivo.errors?.required)"
                class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> El objetivo es requerido.</div>
              </div>

              <div class="form-group mb-0">
                <div class="checkbox-animated mt-0">
                  <input id="privacidad" type="checkbox" formControlName = "privacidad">
                  <label class="text-muted" for="checkbox1">De acuerdo con<a class="ml-2" routerLink='/'>Aviso de privacidad</a></label>
                  <div *ngIf="registerFormSubmitted && (rf.privacidad.invalid || rf.privacidad.errors?.required)"
                class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i>Debe de aceptar la política de privacidad para crear un usuario.</div>
                </div>
                <button class="btn btn-primary btn-block" type="submit"  >Crear cuenta</button>
                
              </div>
              <!-- <h6 class="text-muted mt-4 or">O ingresar con</h6>
              <div class="social mt-4">
                <div class="btn-showcase">
                  
                  <a class="btn btn-light" href="https://twitter.com/login?lang=en" target="_blank">
                   <app-feather-icons [icon]="'twitter'" class="txt-twitter"></app-feather-icons>twitter
                  </a>
                  <a class="btn btn-light" href="https://www.facebook.com/" target="_blank">
                    <app-feather-icons [icon]="'facebook'" class="txt-fb"></app-feather-icons>facebook
                  </a>
                </div>
              </div> -->
              <p class="mt-4 mb-0">¿Ya tienes una cuenta?<a class="ml-2" [routerLink]="'/authentication/login/simple'">Iniciar Sesión</a></p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>