import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from './../../../../environments/environment';
import { Observable } from "rxjs/Observable";
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CrudService {
  static customer: any;
  public showLoader: boolean = false;

  constructor(private http: HttpClient) { 

  }

  public create(model, data):Observable<any> {
    return this.http.post(`${environment.url_api}/${model}.json`, data)
  }

  public get(model, id):Observable<any> {
    return this.http.get(`${environment.url_api}/${model}/${id}.json`)
  }

  public getAll(model):Observable<any> {
    return this.http.get(`${environment.url_api}/${model}.json`)
  }

  public update(model, data):Observable<any> {
    return this.http.put(`${environment.url_api}/${model}/${data['id']}.json`, data)
  }

  public delete(model,id):Observable<any> {
    return this.http.delete(`${environment.url_api}/${model}/${id}.json`)
  }

  public delete_img(model, data):Observable<any> {
    return this.http.post(`${environment.url_api}/${model}/${data['id']}/delete_image.json`,data)
  }


  public get_subscription(model, data):Observable<any> {
    return this.http.post(`${environment.url_api}/${model}/subscribe.json`, data)
  }

  public reset_password(model, data):Observable<any> {
    return this.http.post(`${environment.url_api}/${model}/restore_password.json`, data)
  }

  public restore_password(model, data):Observable<any> {
    return this.http.put(`${environment.url_api}/${model}/update_password.json`, data)
  }

  public static get_customer(): any{
    return CrudService.customer;
  }

  public static set_customer(cust: any): void{
   CrudService.customer = cust;
 }

 public dashboard():Observable<any> {
  return this.http.get(`${environment.url_api}/dashboard.json`)
}


  
}
