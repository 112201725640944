import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudService } from 'src/app/shared/services/firebase/crud.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public PasswordForm: FormGroup;
  public params: any;
  public responce: any = [];

  constructor(
    private router: Router,
    private route_params:ActivatedRoute,
    public crudService:CrudService,
    private fb: FormBuilder
  ) 
  {
    this.route_params.params.subscribe(params => {
      this.params = params;
    });

    this.PasswordForm = fb.group({
      email: ['', [Validators.required]]
    });
   }

  ngOnInit() {
  }

  reset_password(){
    var users = {
      "email": this.PasswordForm.value['email']
    }
    this.crudService.reset_password('users',users).subscribe(
      data => {this.responce = data;
      this.router.navigate(['./login/']);
    });
  }
  

}
