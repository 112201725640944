import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from './../../../../environments/environment';
import { Observable } from "rxjs/Observable";
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  static customer: any;
  public showLoader: boolean = false;

  constructor(private http: HttpClient) { 

  }

  public report(filename:string, path?:string):void {

    if(!path){
      path = "/users/" + filename;
    }
    
    this.http.get(`${environment.url_api}/${path}`,{ responseType: 'blob' as 'json'})
    .subscribe((data) => this.downloadFile(data,filename));
  }

  public downloadFile(data:any, filename:string):void{
    filename = filename.split('?')[0];
    let binaryData = [];
      binaryData.push(data);
      let downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: "application/ms-excel" }));
    
      downloadLink.setAttribute('download', filename);
      document.body.appendChild(downloadLink);
      downloadLink.click();
  }



  
}
