import { Routes } from '@angular/router';


export const content: Routes = [
  {
    path: 'sample-page',
    loadChildren: () => import('../../components/sample/sample.module').then(m => m.SampleModule)
  },
  {
    path: 'customer',
    loadChildren: () => import('../../customer/customer.module').then(m => m.CustomerModule),
  },
  {
    path: 'products',
    loadChildren: () => import('../../products/products.module').then(m => m.ProductsModule),
  },
  {
    path: 'categories',
    loadChildren: () => import('../../categories/categories.module').then(m => m.CategoriesModule),
  }
];
