<div class="container-fluid">
  <div class="page-title">
    <div class="row">
      <div class="col-6">
        <h3></h3>
      </div>
      <div class="col-6">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="['/customer/']"><i class="fa fa-home"></i></a></li>
          <li class="breadcrumb-item active"> Registro de pago</li>
        </ol>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-header">
          <h5>Datos</h5>
        </div>
        <div class="card-body add-post">
          <form class="row needs-validation" [formGroup]="SubscriptionForm">
            <div class="col-sm-12">
             
              <div class="form-group">
                <div class="col-form-label">Cliente:
                  <ng-select2 id="select2-customer" class="js-example-placeholder-multiple col-sm-12" [data]="customers" formControlName="user_id" [placeholder]="'Seleccione una opción...'" [allowClear]="true" (valueChanged)="get_customer($event)" width="100%" style="width: 100%;"></ng-select2> 
                </div>
                <div *ngIf="sendForm && (rf.user_id.invalid || rf.user_id.errors?.required)" class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Cliente es requerido.</div>
              </div>

      
              
              <div *ngIf="customer" >
                <ul *ngIf="customer.email">
                  <li>Email: <span class="font-primary">{{ customer.email }}</span></li>
                  <li>Cumpleaños: <span class="font-primary">{{customer.birthday }} </span></li>    
                  <li>Fecha de inscripción: <span class="font-primary">{{currentDate | date:'short' }} </span></li>       
                </ul>
              </div>
              <br><br>

              <div class="form-group">
                <div class="col-form-label">Producto:
                  <ng-select2  id="select2-product" class="js-example-placeholder-multiple col-sm-12" [data]="products" formControlName="product_id" [placeholder]="'Seleccione una opción...'" [allowClear]="true" (valueChanged)="get_product($event)" width="100%" style="width: 100%;"></ng-select2> 
                </div>
                <div *ngIf="sendForm && (rf.product_id.invalid || rf.product_id.errors?.required)" class="help-block mt-1 text-danger"> <i class="ft-alert-circle align-middle"></i> Producto es requerido.</div>
              </div>

              <div class="form-group">
                <div *ngIf="product" class="form-group">
                  <div *ngIf="product.price" class="col-form-label">Costo:
                    <div [innerHtml] = "product.price" ></div>
                  </div>
                </div>
                <div *ngIf="product" >
                  <div *ngIf="product.description" class="col-form-label">Descripción:
                    <div [innerHtml] = "product.description" ></div>
                  </div>
                </div>
              </div>

              

              

        

              <div class="btn-showcase">
                <button class="btn btn-primary"
                    [class.loader--text]="showLoader"
                    [disabled]="showLoader"
                    (click)="new_subscription()"
                    type="button"><span>{{ showLoader ? '' : 'Guardar' }}</span>
                </button>
                <a class="btn btn-primary" [routerLink]="'/customer/payment-register'" >
                  Cancelar
                </a>
              </div>
            </div>
          </form>
  
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Container-fluid Ends-->
