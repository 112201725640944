import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CrudService } from 'src/app/shared/services/firebase/crud.service';
import { Select2OptionData } from 'ng-select2';


@Component({
  selector: 'app-payment-register',
  templateUrl: './payment-register.component.html',
  styleUrls: ['./payment-register.component.scss']
})
export class PaymentRegisterComponent implements OnInit {
  public SubscriptionForm: FormGroup;
  dtOptions: DataTables.Settings = {};
  public customers: Array<Select2OptionData>;
  public products: Array<Select2OptionData>;
  public subscription: any[] = [];
  public product = null;
  public customer = null;
  public sendForm = false;
  public currentDate = new Date();
  public showLoader: boolean = false;

  constructor(
    private router: Router,
    private crudService:CrudService,
    private fb: FormBuilder
  ) 
  {
    this.get_users()
    this.get_products()
    
    this.SubscriptionForm = fb.group({
      user_id: ['', [Validators.required]],
      product_id: ['', Validators.required],
    });

   }

  ngOnInit() {
    
  }

  


  get rf() {
    return this.SubscriptionForm.controls;
  }

  get_users(){
      this.crudService.getAll("users").subscribe(data => {
        let arrCustomers = [];
        for (let i = 0; i < data.length; i++) {
          arrCustomers.push({id: data[i].id.toString(), text: data[i].full_name })
        }
        this.customers = arrCustomers;
      });
      
  }

  get_products(){
    this.crudService.getAll("products").subscribe(data => {
      let arrProducts = [];
      for (let i = 0; i < data.length; i++) {
        arrProducts.push({ id: data[i].id.toString(), text: data[i].name })
      }
      this.products = arrProducts;
    });
    
}

get_product(id){
  if (id){
    this.crudService.get('products',id).subscribe(data => {
      this.product = data;
    });
  }
  
}

get_customer(id){
  if (id){
    this.crudService.get('users',id).subscribe(data => {
      this.customer = data;
    });
  }
  
}


  new_subscription(){
    this.sendForm = true;
    if(this.findInvalidControls().length > 0){
      console.log("Invalid!")
      return;
    }

    var subscription = {
      "user_id": this.SubscriptionForm.value['user_id'],
      "product_id": this.SubscriptionForm.value['product_id'],
      "payment_type": 1//Efectivo
    }
    if(confirm("Esta seguro que desea registrar el pago")) {
      this.crudService.get_subscription('subscriptions',subscription).subscribe(
        data => { this.subscription = data;
                alert("Pago registrado exitosamente ");
                this.router.navigate(['./customer/payment-register']);
                $("#select2-customer select").val(['']).trigger('change');
                $("#select2-product select").val(['']).trigger('change');
                this.product = null;
                this.customer = null;
                
        },
        error => {alert("Ha ocurrido un error  "+error["message"])}
        
      );
    }
  }

  findInvalidControls(){
    const invalid = [];
    const controls = this.SubscriptionForm.controls;
    for (const name in controls) {
      if(controls[name].invalid){
        invalid.push(name);
      }
    }
    return invalid;
  }


}
