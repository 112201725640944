import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoriesService } from 'src/app/shared/services/categories.service';
import { ProductsService } from '../../shared/services/products.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  public categories: any[] = [];
  public category: any[] = [];

  constructor(
    private router: Router,
    private productsService:ProductsService,
    private categoriesService:CategoriesService) 
    { 
    

  
  }

  ngOnInit(){
    this.get_categories();
    
    // this.dtOptions = {
    //   data: this.categories,
    //   pagingType: 'full_numbers',
    //   language: {
    //     "processing": "Procesando",
    //     "lengthMenu": "Mostrar _MENU_ registros",
    //     "emptyTable": "Ningún dato disponible en esta tabla",
    //     "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
    //     "infoEmpty": "Mostrando 0 registros del 0 de entradas",
    //     "infoFiltered": "(filtrado de un total de _MAX_ registros)",
    //     "search": "Buscar",
    //     "infoPostFix": "",
    //     "url": "",
    //     "zeroRecords": "No se encontraron resultados",
    //     "loadingRecords": "Cargando...",
    //     "paginate":{
    //       "first": "Primero",
    //       "last": "Último",
    //       "next": "Siguiente",
    //       "previous": "Anterior"
    //     },
    //     "aria": {
    //       "sortAscending": ": Activar para ordenar la columna de manera ascendente",
    //       "sortDescending": ": Activar para ordenar la columna de manera descendente"
    //     }

        
        
    //   }
    // };
  }

  get_categories(){
    this.productsService.getAllCategories().subscribe(data => {
      this.categories = data;
      window.setTimeout( function (){ $('#cat-index').DataTable(
        {
          pagingType: 'full_numbers',
          language: {
            "processing": "Procesando",
            "lengthMenu": "Mostrar _MENU_ registros",
            "emptyTable": "Ningún dato disponible en esta tabla",
            "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
            "infoEmpty": "Mostrando 0 registros del 0 de entradas",
            "infoFiltered": "(filtrado de un total de _MAX_ registros)",
            "search": "Buscar",
            "infoPostFix": "",
            "url": "",
            "zeroRecords": "No se encontraron resultados",
            "loadingRecords": "Cargando...",
            "paginate":{
              "first": "Primero",
              "last": "Último",
              "next": "Siguiente",
              "previous": "Anterior"
            },
            "aria": {
              "sortAscending": ": Activar para ordenar la columna de manera ascendente",
              "sortDescending": ": Activar para ordenar la columna de manera descendente"
            }
    
            
            
          }
        }
      )}, 1000);
      //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    });
  }

  delete_category(id){
    if(confirm("Esta seguro que desea eliminar")) {
      this.categoriesService.delete_category(id).subscribe(
        data => { this.category = data;
                  this.router.navigate(['./categories/']);
                  document.querySelector('#tr_category_'+id).remove();
                },
        error => {alert("Ha ocurrido un error  "+error["message"])}
      );
    }
  }

}
