<div class="container-fluid">
    <div class="page-title">
      <div class="row">
        <!--<div class="col-6">
          <h3>Crear Plan</h3>
        </div>-->
        <div class="col-6">
          <ol class="breadcrumb">
            <!-- <li class="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li> -->
            <li class="breadcrumb-item"><a [routerLink]="['/customer/']"><i class="fa fa-home"></i></a></li>
            <li class="breadcrumb-item active"> Editar Categoría</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h5>Editar Categoría</h5>
          </div>
          <div class="card-body add-post">
            <form class="row needs-validation" [formGroup]="CategoryForm">
                <div class="col-sm-12">
                  <div class="form-group">
                    <input [(ngModel)]="category.name" class="form-control" id="validationCustom01" type="text" placeholder="Nombre de categoría" formControlName="category_name">
                    <div class="valid-feedback">Looks good!</div>
                  </div>

                  
  
                  <div class="btn-showcase">
                    <button class="btn btn-primary"
                        (click)="edit_category()"
                        type="button"><span>Guardar</span>
                    </button>
                    <a class="btn btn-primary" [routerLink]="'/categories'" >
                      Cancelar
                    </a>
                  </div>
                </div>
              </form>
            
         
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->
