import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select2OptionData } from 'ng-select2';
import { CrudService } from 'src/app/shared/services/firebase/crud.service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})
export class EditProductComponent implements OnInit {
  public ProductForm: FormGroup;
  public product: any = [];
  public categories: Array<Select2OptionData>;
  public params: any;
  public base64Image: Array<string> = null;
  config_desc: any = {};
  config_content: any = {};


  constructor(
    private router: Router,
    private route_params:ActivatedRoute,
    public crudService:CrudService,
    private fb: FormBuilder) 
  {

    this.route_params.params.subscribe(params => {
      this.params = params;
    });


    this.crudService.getAll('categories').subscribe(data => {
      let arrCategories = [];
      for (let i = 0; i < data.length; i++) {
        arrCategories.push({id: data[i].id.toString(), text: data[i].name})
      }
      this.categories = arrCategories;
    });

    
    this.crudService.get('products',this.params['id']).subscribe(data => {
      this.product = data;
    });

    this.base64Image = new Array<string>();


    this.ProductForm = fb.group({
      plan_name: ['', [Validators.required]],
      plan_content: ['', Validators.required],
      plan_description: ['', Validators.required],
      plan_cost: ['', [Validators.required]],
      category_id: ['', [Validators.required]],
      frecuency: ['', [Validators.required]],
      pictures: ['', [Validators.required]]
    });
   }



  ngOnInit(){

    this.config_content = {
      toolbar: [
        ['uiColor', '#dddddd'],
        ['Maximize'],
        ['Format', 'FontSize'],
        ['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat'],
        ['TextColor', 'BGColor'],
        ['NumberedList', 'BulletedList'],
        ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'],
        ['Cut', 'Copy'],
        ['Undo', 'Redo']
      ],
      height: 100
      
    };

    this.config_desc = {
      height: 300
    };

    
    

  }

  handleFileInput(files: FileList) {
    for(let i = 0; i < files.length; i++){
      let fileReader = new FileReader();
      fileReader.readAsDataURL(files.item(i));
      fileReader.onloadend = (e) => {
        this.base64Image.push(fileReader.result.toString());
        this.product.images_url.push(fileReader.result)
        console.log("file: "+i+" - "+this.base64Image[i]);
      }
    }
  }



  parse_img(img): string{
    if(img.indexOf('data') >= 0){
      return img;
    }else{
      return  environment.url_api + img;
    }
  }



  edit_product(){
    var product = {
      "id": this.params['id'],
      product:{
      
      "name": this.ProductForm.value['plan_name'], 
      "description": this.ProductForm.value['plan_description'],
      "content": this.ProductForm.value['plan_content'],
      "price": this.ProductForm.value['plan_cost'],
      "category_id": this.ProductForm.value['category_id'],
      "frecuency": this.ProductForm.value['frecuency'],
      "images": JSON.stringify(this.base64Image)
    }
    }
    this.crudService.update('products',product).subscribe(
      data => {this.product = data;
      this.router.navigate(['./products/']);
    });
  }

  delete_image(id, i){
    console.log("IMAGES11111----"+(this.product.images_url));
    if(confirm("Desea eliminar esta imágen?")) {
      document.querySelector('#img_din_'+i).remove();
      //var index = this.product.images_url.indexOf(img);
      //this.product.images_url.splice(index, 1); 
      
      this.crudService.delete_img('products',{index:i, id:id}).subscribe(
        data => {this.product = data;
      });
      console.log("IMAGES----"+(this.base64Image));
    }
  }


  onSelect(event) {
    console.log(event);
    //this.fileToUpload.push(...event.addedFiles);
  }
  
  onRemove(event) {
    console.log(event);
    //this.fileToUpload.splice(this.fileToUpload.indexOf(event), 1);
  }

 


  

}
