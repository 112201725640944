<div class="container-fluid">
    <div class="page-title">
      <div class="row">
        <div class="col-6">
          <h3>Editar Plan</h3>
        </div>
        <div class="col-6">
          <ol class="breadcrumb">
            <!-- <li class="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li> -->
            <li class="breadcrumb-item"><a [routerLink]="['/customer/']"><i class="fa fa-home"></i></a></li>
            <li class="breadcrumb-item active"> {{product.name}}</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
<div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <h5>Editar Plan</h5>
          </div>
          <div class="card-body add-post">
            <form class="row needs-validation" [formGroup]="ProductForm">
              <div class="col-sm-12">
                <div class="form-group">
                  <input [ngModel]="product.name" class="form-control" id="validationCustom01" type="text" placeholder="Nombre del Plan" formControlName="plan_name" >
                  <div class="valid-feedback">Looks good!</div>
                </div>
               
                <div class="form-group">
                  <div class="col-form-label">Categoria:
                    <ng-select2 [(ngModel)]="product.category_id" class="js-example-placeholder-multiple col-sm-12" [data]="categories" formControlName="category_id"[placeholder]="'Seleccione una opción...'" width="100%" style="width: 100%;"></ng-select2>
                  </div>
                </div>


                <div class="form-group">
                  <div class="col-form-label">Periodo:
                    <!-- <input [(ngModel)]="product.frecuency" class="form-control" id="validationCustom01" type="text" placeholder="Periodo" formControlName="frecuency"> -->
                    <select [(ngModel)]="product.frecuency" class="form-control" id="validationCustom01" formControlName = "frecuency">
                      <option>Seleccione una opción</option>
                      <option value="Mensual">Mensual</option>
                      <option value="Anual">Anual</option>
                  </select>
                  </div>
                </div>


                

                <div class="form-group">
                  <label>Costo:</label>
                  <input [(ngModel)]="product.price" class="form-control" id="validationCustom01" type="text" placeholder="Costo Mensual"  formControlName="plan_cost">
                </div>


                


                <div class="form-group">
                  <label>Contenido Plan:</label>
                  <ckeditor [(ngModel)]="product.content"
                            [config]="config_content"
                            [readonly]="false"
                            formControlName="plan_content"
                            >
                  </ckeditor>

                  <!-- <ckeditor [(ngModel)]="product.description"
                            [config]="{uiColor: '#99000'}"
                            [readonly]="false"
                            (change)="onChange($event)"
                            (editorChange)="onEditorChange($event)"
                            (ready)="onReady($event)"
                            (focus)="onFocus($event)"
                            (blur)="onBlur($event)"
                            (contentDom)="onContentDom($event)"
                            (fileUploadRequest)="onFileUploadRequest($event)"
                            (fileUploadResponse)="onFileUploadResponse($event)"
                            (paste)="onPaste($event)"
                            (drop)="onDrop($event)"
                            debounce="500">
                  </ckeditor> -->
                </div>


                <div class="form-group">
                  <label>Descripción Plan:</label>
                  <ckeditor [(ngModel)]="product.description"
                            [config]="config_desc"
                            [readonly]="false"
                            formControlName="plan_description"
                            >
                  </ckeditor>

                </div>


                
               <!-- <div class="dropzone" id="singleFileUpload">
                  <div class="m-0 dz-message needsclick"><i class="icon-cloud-up"></i>
                    <h6 class="mb-0">Subir Imágen / imágenes</h6>
                    <input type="file" id="file" multiple="true" (change)="handleFileInput($event.target.files)" formControlName="pictures">
                  </div>
                </div>   -->







                  <div class="form-group">
                    <div class="dropzone" id="singleFileUpload">
                      <div class="m-0 dz-message needsclick"><i class="icon-cloud-up"></i><br><br>
                        <input type="file" id="file" multiple="true" (change)="handleFileInput($event.target.files)" formControlName="pictures">
                        <img *ngFor="let img of product.images_url; let i = index" (click)="delete_image(product.id, i)" class="img-dinamic img-100 rounded-circle" id="img_din_{{i}}" src="{{parse_img(img)}}" alt="" height="100"> 
                      </div>
                    </div>
                    
                  </div>
               


                <!-- <ngx-dropzone class="dropzone" id="singleFileUpload" (change)="Files($event)">
                  <div class="m-0 dz-message needsclick"><i class="icon-cloud-up"></i>
                    <h6 class="mb-0">Subir Imágen / imágenes</h6>
                    <ngx-dropzone-preview *ngFor="let f of fileToUpload" [removable]="true" (removed)="onRemove(f)">
                      <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                    </ngx-dropzone-preview>
                  </div>
                </ngx-dropzone> -->
                






                <div class="btn-showcase">
                  <button class="btn btn-primary"
                      (click)="edit_product()"
                      type="button"><span>Guardar</span>
                  </button>
                  <a class="btn btn-primary" [routerLink]="'/products'" >
                    Cancelar
                  </a>
                </div>
              </div>
            </form>
            
            <!--<div class="btn-showcase">
              <button class="btn btn-primary" type="submit">Guardar</button>
              <button class="btn btn-primary" type="submit">Cancelar</button>

            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Container-fluid Ends-->

