import { ApiService } from './api.service';
import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from "@angular/common/http";
//import { Observable } from "rxjs/Observable";
import { Observable } from "rxjs/Rx"
import 'rxjs/add/operator/do';
import { shareReplay } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import * as moment from "moment";
import { User } from '../models/user';
import { stringify } from '@angular/compiler/src/util';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
static user: any;
static sesion: any;
static subscriptions: any;
public destroy: any;
constructor(private http: HttpClient,
    
  public router: Router,
  public ngZone: NgZone,
  public toster: ToastrService,
  private cookieService: CookieService,
  private api :ApiService,
  ) { }
  public showLoader: boolean = false;

getAutenticatedUser(): User {
  const model = JSON.parse(localStorage.getItem('user'));
  return model;
}

get isLoggedIn(): boolean {
  const user = JSON.parse(localStorage.getItem('user'));
  return (user != null && user.emailVerified != false) ? true : false;
}

registerUser(user:any):Observable<any>{
   //return this.http.post(`${environment.url_api}/signup/`, user);
  const userData = this.api.postResponse('/signup/',false, user).do(res => this.setSession);
  
  return userData;
}

loginUser(user:any){
  return this.api.postResponse('/login/', false, user)
  .do(res => {
    console.log("do"+JSON.stringify(res['body']['id']));
    AuthService.set_sesion(res);
    //this.extractDataAndSetAuthHeader(res);
  });
  
  //localStorage.setItem('user', JSON.stringify(loginModel));
 }



 //extractDataAndSetAuthHeader(res: Response) {

  // Set auth header if available.
  // If not available - user is not logged in. Then 
  // we also have to remove the token from localStorage
//   if(res.headers.has("Authorization"))
//   {
//       let token = res.headers.get("Authorization");
//       localStorage.setItem('id_token', token);
//   }
//   else
//   {
//       // If no token is sent, remove it
//       localStorage.removeItem('id_token');
//   }
// }




 private setSession(authResult) {
   console.log("authResult", authResult);
  const expiresAt = moment().add(authResult.expiresIn,'second');
  localStorage.setItem('id_token', authResult.idToken);
  localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()) );
}      

getExpiration() {
  const expiration = localStorage.getItem("expires_at");
  const expiresAt = JSON.parse(expiration);
  return moment(expiresAt);
}    

 logOutUser():Observable<any>{
  localStorage.removeItem("id_token");
  localStorage.removeItem("expires_at");
  localStorage.removeItem("token");
  localStorage.removeItem("remember");
  this.destroy = AuthService.get_sesion();
  delete this.destroy.headers.authorization;
  
  return this.http.delete(`${environment.url_api}/logout/`);
 }


 DestroySesion(){
  localStorage.removeItem("id_token");
  localStorage.removeItem("expires_at");
  localStorage.removeItem("token");
  localStorage.removeItem("remember");
  AuthService.set_sesion(null);
  
  this.http.delete(`${environment.url_api}/logout/`);
  //console.log("destroy sesion"+JSON.stringify(AuthService.get_sesion()));
 }

 public static set_user(user: any): void{
   AuthService.user = user;
 }

 public static start_session(): boolean{
   if(AuthService.user){
     return true;
   }

   if(localStorage.getItem('user') && localStorage.getItem('remember')){
       AuthService.set_user(JSON.parse(localStorage.getItem('user'))['body']);
   }else{
     AuthService.destroy_session();
     window.location.href = '/auth/login';
   }
 }

 public static get_user(): any{
   AuthService.start_session();
   return AuthService.user;
 }

 public static destroy_session(): null{
   localStorage.removeItem("id_token");
   localStorage.removeItem("expires_at");
   localStorage.removeItem("token");
   localStorage.removeItem("remember");
   AuthService.set_sesion(null);
   return null;
 }

 public static set_sesion(sesion: any): void{
  AuthService.sesion = sesion;
}

public static get_sesion(): any{
  return AuthService.sesion;
}

 public static set_subscriptions(user: any): void{
  AuthService.subscriptions = user;
}

public static get_subscriptions(): any{
  return AuthService.subscriptions;
}





}
