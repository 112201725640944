<div class="container-fluid">
  <div class="page-title">
    <div class="row">
      <div class="col-6">
        <h3>Reporte pagos</h3>
      </div>
      <div class="col-6">
        <ol class="breadcrumb">
          <!-- <li class="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li> -->
          <li class="breadcrumb-item"><a [routerLink]="['/customer/']"><i class="fa fa-home"></i></a></li>
          <li class="breadcrumb-item active">{{ current_user.role_name == 'User' ? 'Historial de Pagos' : 'Historial Clientes' }}</li>
       
        </ol>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="col-sm-12">
      <div class="card">
         <div class="card-header">

           <div class="col-sm-6" >     
              <!-- 
            <a click="generateReport()" class="btn btn-primary" *ngIf="current_user.role_name == 'Admin'" >Reporte</a>        
              -->
              <!-- <a class="btn btn-primary" [routerLink]="'/products/new'" >
                <i class="icon-plus"></i>Nuevo Plan
              </a> -->
          </div>

         </div>

         

         <div class="card-body">
           <div class="table-responsive">
             <table class="display" id="basic-1">
               <thead>
                 <tr>
                   <th>Nombre del plan</th>
                   <th>Costo</th>
                   <th>Periodo</th>
                   <th>Fecha de contratación</th>
                   <th>Fecha vencimiento</th>
                   <th>Pagado</th>
                   <th>Tipo de pago</th>
                   <th>Confirmación pago</th>
                 </tr>
               </thead>
               <tbody>
                 <tr *ngFor="let subscription of user.historial_subscriptions">
                   <td>{{subscription.product.name}}</td>
                   <td align="center">{{subscription.product.price}}</td>
                   <td>{{subscription.product.frecuency}}</td>
                   <td>{{subscription.created_at | date:'dd/M/yyyy'}}</td>
                   <td>{{subscription.end_date | date:'dd/M/yyyy'}}</td>
                   <td align="center">{{ subscription.status_name == 'Activa' ? 'Si' : 'No' }}</td>
                   <td>{{subscription.payment_name}}</td>
                   <td>
                    <a class="" [routerLink]="['/customer/detail-payment/',subscription.id]" >
                      Ver más
                    </a>
                  </td>
               
                 </tr>        
               </tbody>
             </table>
           </div>
         </div>
       </div>
     </div>
 </div>
 