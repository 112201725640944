<div class="container-fluid p-0">
    <div class="row">
      <div class="col-12">     
        <div class="login-card">
          <div>
            <div>
              <!-- <a class="logo"  routerLink='/'>
                <img class="img-fluid for-light" src="assets/images/logo/rackup_negro.png" alt="looginpage">
                <img class="img-fluid for-dark" src="assets/images/logo/rackup_blanco.png" alt="looginpage">
              </a> -->
            </div>
            <div class="login-main"> 
              <form class="theme-form needs-validation" [formGroup]="PasswordForm">
                <h4>Restablecer contraseña</h4>
                <p>Ingresa tu correo</p>
                <div class="form-group">
                  <label class="col-form-label">Correo electrónico</label>
                  <input class="form-control" type="email" placeholder="app@tucorreo.com" formControlName="email">
                    <!-- <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
                        class="text text-danger mt-1">
                        Correo electrónico es requerido
                    </div>
                    <div *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
                        class="text text-danger mt-1">
                        Correo electrónico inválido
                    </div> -->
                </div>
           
                <p class="mt-4 mb-0">
                  <!-- <a [routerLink]="'/auth/register'" class="ml-2">Enviar instrucciones</a> -->
                  <button class="btn btn-primary btn-block"
                      (click)="reset_password()"
                      type="button"><span>Enviar</span>
                  </button>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
