// import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  token: string = 'Bearer ' + localStorage.getItem('token');
  isConnected = false;
  validar: boolean = false;

  constructor(
    private http: HttpClient
    //, private auth: AuthService
    ) {
  }

  getResponse(url: String, auth?: Boolean, params?: any) {
    
    if (auth) {
      this.token = '';// Bearer ' + this.auth.getAutenticatedUser().access_token;
      const httpOptions = {
        headers: new HttpHeaders({ 'Authorization': this.token })
      };
      return this.http.get<any>(`${environment.url_api}${url}`, httpOptions);
    }
    else {
      return this.http.get<any>(`${environment.url_api}${url}`);
    }

  }

  postResponse(url: String, auth?: Boolean, params?: any) {
    
    if (auth) {
      this.token = '' ; //'Bearer ' + this.auth.getAutenticatedUser().access_token;
      const httpOptions = {
        headers: new HttpHeaders({ 'Authorization': this.token, 'Content-Type': 'application/json' }),
      };
      
      return this.http.post<any>(`${environment.url_api}${url}`, JSON.stringify(params), httpOptions);
    }
    else {
   
      return this.http.post<any>(`${environment.url_api}${url}`, params, {observe: 'response'});
    }

  }

  fileResponse(url: String, auth?: Boolean, params?: any) {
    
    if (auth) {
      this.token = '' ; //'Bearer ' + this.auth.getAutenticatedUser().access_token;
      const httpOptions = {
        headers: new HttpHeaders({
          'Authorization': this.token,
          'enctype': 'multipart/form-data'
        })
      };
      return this.http.post<any>(`${environment.url_api}${url}`, params, httpOptions);
    }
    else {
      return this.http.post<any>(`${environment.url_api}${url}`, params);
    }

  }

  deleteResponse(url: String, auth?: Boolean, params?: any) {
    
    if (auth) {
      this.token = '' ; //'Bearer ' + this.auth.getAutenticatedUser().access_token;
      const httpOptions = {
        headers: new HttpHeaders({ 'Authorization': this.token })
      };
      return this.http.delete<any>(`${environment.url_api}${url}`, httpOptions);
    }
    else {
      return this.http.delete<any>(`${environment.url_api}${url}`);
    }


  }

  pdfResponse(url: String, auth?: Boolean, params?: any) {
    
    if (auth) {
      this.token = '' ; //'Bearer ' + this.auth.getAutenticatedUser().access_token;
      const httpOptions = {
        headers: new HttpHeaders(
          {
            'Authorization': this.token
          }),
        responseType: 'blob' as 'json',
      };
      return this.http.get<any>(`${environment.url_api}${url}`, httpOptions);
    }
    else {
      return this.http.get<any>(`${environment.url_api}${url}`);
    }
  }
}

