import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CrudService } from 'src/app/shared/services/firebase/crud.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  public UserForm: FormGroup;
  public user: any;
  public base64Image: string;
  public fileToUpload: File = null;
  


  constructor(
    private router: Router,
    private route_params:ActivatedRoute,
    public crudService:CrudService,
    private fb: FormBuilder
  ) 
  { 
    this.user = AuthService.get_user();
    console.log("active subscription"+this.user.active_subscriptions.length);

    


    this.UserForm = fb.group({
      user_name: ['', [Validators.required]],
      lastname: ['', Validators.required],
      second_lastname: ['', [Validators.required]],
      user_email: ['', [Validators.required]],
      password: ['', [Validators.required]],
      password_confirmation: ['', [Validators.required]],
      telephone: ['', [Validators.required]],
      birthday: ['', [Validators.required]],
      avatar: ['', [Validators.required]]
    });
   }

   ngOnInit() {
   
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    let reader = new FileReader();
    reader.readAsDataURL(this.fileToUpload);
    reader.onloadend = (e) => {
      this.base64Image = reader.result.toString();
      this.user.avatar_img = reader.result;
      console.log("files"+this.base64Image);
    }
  }


  parse_avatar_img(avatar_url): string{
    if(avatar_url){

    return  environment.url_api + avatar_url;
    }
    else{
      return '../assets/images/user/2.png'
    }
  }


  parse_img_preview(img): string{
    console.log("IMG:"+img)
    if(img.indexOf('data') >= 0){
      return img;
    }else{
      return  environment.url_api + img;
    }
  }


  edit_user(){
    var user = {
      "id": this.user.id,
      "user": {
        "name": this.UserForm.value['user_name'], 
        "lastname": this.UserForm.value['lastname'],
        "second_lastname": this.UserForm.value['second_lastname'],
        //"email": this.UserForm.value['user_email'],
        "password": this.UserForm.value['password'],
        "password_confirmation": this.UserForm.value['password_confirmation'],
        "telephone": this.UserForm.value['telephone'], 
        "birthday": this.UserForm.value['birthday'], 
        "avatar": this.base64Image
        
      }
    }
    this.crudService.update('users',user).subscribe(
      data => {this.user = data;
      AuthService.set_user(data);
      
      this.router.navigate(['./customer/']);
    });
  }

}

  


