
  <div class="container-fluid">
    <div class="page-title">
      <div class="row">
        <div class="col-6">
          <h3>Editar Mi Cuenta</h3>
        </div>
        <div class="col-6">
          <ol class="breadcrumb">
            <!-- <li class="breadcrumb-item"><a href="index.html"><i data-feather="home"></i></a></li> -->
            <li class="breadcrumb-item"><a [routerLink]="['/customer/']"><i class="fa fa-home"></i></a></li>
            <li class="breadcrumb-item active"> Mi Cuenta</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="edit-profile">
      <div class="row">
        <div class="col-xl-4">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title mb-0">Foto Perfil</h4>
              <div class="card-options"><a class="card-options-collapse" href="#" data-toggle="card-collapse"><i
                    class="fe fe-chevron-up"></i></a><a class="card-options-remove" href="#"
                  data-toggle="card-remove"><i class="fe fe-x"></i></a></div>
            </div>
            <div class="card-body">
              <form>
                <div class="row mb-2">
                  <div class="col-auto"> <br><br>
                    <br><br>
                    <!-- <br><img class="img-70 rounded-circle" alt="" src="../assets/images/user/7.jpg"> -->
                    <br><img class="img-100 rounded-circle" src="{{parse_avatar_img(user.avatar_img)}}" alt="" height="100">
                  </div>
                  <div class="col">
                    <br><br>
                    <br><br>
                    <br>
                    <h3 class="mb-1">{{user.name}}</h3>
                    <p class="mb-4">{{user.role_name}}</p>
                  </div>
                </div>


                <br> <br>


                <div class="card-footer text-right">
                </div>

              </form>
            </div>
          </div>
        </div>
        <div class="col-xl-8">
          <form class="card" [formGroup]="UserForm">
            <div class="card-header">
              <h4 class="card-title mb-0">Mis Datos</h4>
              <div class="card-options"><a class="card-options-collapse" href="#" data-toggle="card-collapse"><i
                    class="fe fe-chevron-up"></i></a><a class="card-options-remove" href="#"
                  data-toggle="card-remove"><i class="fe fe-x"></i></a></div>
            </div>
            <div class="card-body">

              <div class="col-sm-12">
                <ul class="nav nav-tabs border-tab mb-0" id="top-tab" role="tablist">
                  <li class="nav-item"><a class="nav-link active" id="top-home-tab" data-toggle="tab"
                      href="#top-home" role="tab" aria-controls="top-home" aria-selected="false">Mis Datos
                      Personales</a>
                    <div class="material-border"></div>
                    
                  </li>
                  <!-- <li class="nav-item"><a class="nav-link" id="top-home-tab" data-toggle="tab" href="#top-home"
                      role="tab" aria-controls="top-home" aria-selected="false">Datos Bancarios</a>
                    <div class="material-border"></div>
                  </li> -->


                </ul>
                <div class="tab-content" id="top-tabContent">
                  <div class="tab-pane fade active show" id="top-home" role="tabpanel"
                    aria-labelledby="top-home-tab">

                    <div class="row">
                      <div class="col-sm-6 col-md-6">
                        <div class="form-group mb-6">
                          <br><label class="form-label">Nombre</label>
                          <input [(ngModel)]="user.name" formControlName="user_name" class="form-control" placeholder="Nombre">
                        </div>
                      </div>

                      

                      <div class="col-sm-6 col-md-6">
                        <div class="form-group mb-6">
                          <br> <label class="form-label">Apellido paterno</label>
                          <input [(ngModel)]="user.lastname" formControlName="lastname" class="form-control" placeholder="Apellidos">
                        </div>
                      </div>

                      <div class="col-sm-6 col-md-6">
                        <div class="form-group mb-6">
                          <br> <label class="form-label">Apellido materno</label>
                          <input [(ngModel)]="user.second_lastname" formControlName="second_lastname" class="form-control" placeholder="Apellidos">
                        </div>
                      </div>

                      <div class="col-sm-6 col-md-6">
                        <div class="form-group mb-6">
                          <br><label class="form-label">Correo Electrónico</label>
                          <input [(ngModel)]="user.email" formControlName="user_email" class="form-control" placeholder="your-email@domain.com" readonly>
                        </div>
                      </div>

                      <div class="col-sm-6 col-md-6">
                        <div class="form-group mb-6">
                          <br><label class="form-label">Contraseña</label>
                          <input [(ngModel)]="user.password" formControlName="password" class="form-control" type="password">
                        </div>
                      </div>

                      <div class="col-sm-6 col-md-6">
                        <div class="form-group mb-6">
                          <br><label class="form-label">Confirmar contraseña</label>
                          <input [(ngModel)]="user.password_confirmation" formControlName="password_confirmation" class="form-control" type="password" id="conf_pass">
                        </div>
                      </div>


                      <div class="col-sm-6 col-md-6">
                        <div class="form-group mb-6">
                          <br><label class="form-label">Teléfono</label>
                          <input [(ngModel)]="user.telephone" formControlName="telephone" class="form-control" placeholder="Teléfono">
                        </div>
                      </div>

                      <div class="col-sm-6 col-md-6">
                        <div class="form-group mb-6">
                          <br><label class="form-label">Fecha de cumpleaños</label>
                          <input class="form-control" type="date" required="" placeholder="dd/mm/yyyy" formControlName = "birthday" [(ngModel)]="user.birthday" >

                        </div>
                      </div>
                      
                      
                      <div class="col-sm-6 col-md-6">
                        <div class="form-group mb-6">
                          <br><br><br>
                          <a for="file-upload" class="custom-file-upload">
                            <i class="fa fa-cloud-upload">Subir Imágen de perfil&nbsp;&nbsp;&nbsp;</i> 
                            <input type="file" id="file" (change)="handleFileInput($event.target.files)" formControlName="avatar">
                            <img class="b-r-10" src="{{parse_img_preview(user.avatar_img)}}" alt="" height="100" width="100"> 
                          </a>
                        </div>
                      </div>


                    </div>

                  </div>
                  <div class="tab-pane fade" id="top-profile" role="tabpanel" aria-labelledby="profile-top-tab">

                  </div>
                  <div class="tab-pane fade" id="top-contact" role="tabpanel" aria-labelledby="contact-top-tab">

                  </div>

                </div>
              </div>





            </div>


            <div class="card-footer text-right">
              <button class="btn btn-primary" (click)="edit_user()" type="submit">Actualizar Cuenta</button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
