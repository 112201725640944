
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule , HTTP_INTERCEPTORS } from '@angular/common/http';

import { CKEditorModule } from 'ng2-ckeditor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
// for HttpClient import:
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
// for Router import:
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
// for Core import:
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { LoginComponent } from './auth/login/login.component';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';

//import { AuthService } from './shared/services/firebase/auth.service';
import { AdminGuard } from './shared/guard/admin.guard';
import { SecureInnerPagesGuard } from './shared/guard/SecureInnerPagesGuard.guard';
import { CookieService } from 'ngx-cookie-service';
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { DataTablesModule } from 'angular-datatables';
import { NgSelect2Module } from 'ng-select2';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { EmbedVideo } from 'ngx-embed-video';
import {Chart} from 'chart.js'



//Interceptores
import { AuthInterceptorService } from './shared/services/auth-interceptor.service';

//Components
import { IndexComponent } from './products/index/index.component';
import { NewComponent } from './products/new/new.component';
import { CategoriesComponent } from './categories/index/categories.component';
import { NewCategoriesComponent } from './categories/new-categories/new-categories.component';
import { EditProductComponent } from './products/edit-product/edit-product.component';
import { EditCategoryComponent } from './categories/edit-category/edit-category.component';
import { AccountComponent } from './customer/account/account.component';
import { AllUsersComponent } from './customer/all-users/all-users.component';
import { PaymentsComponent } from './customer/payments/payments.component';
import { PaymentRegisterComponent } from './customer/payment-register/payment-register.component';


import {CustomDatePipe} from './shared/date/custom.datepipe.ts '




export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    IndexComponent,
    NewComponent,
    CategoriesComponent,
    NewCategoriesComponent,
    EditProductComponent,
    EditCategoryComponent,
    AccountComponent,
    AllUsersComponent,
    PaymentsComponent,
    CustomDatePipe,
    PaymentRegisterComponent
    
  ],
  imports: [
    BrowserModule,
    CKEditorModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    DataTablesModule,
    NgSelect2Module,
    NgxDropzoneModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
    }),
    // for HttpClient use:
    LoadingBarHttpClientModule,
    // for Router use:
    LoadingBarRouterModule,
    // for Core use:
    LoadingBarModule,
    EmbedVideo.forRoot()
  ],
  providers: [ AdminGuard, SecureInnerPagesGuard, CookieService, 
    {provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
