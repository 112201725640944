import { AuthService } from '../../../shared/services/auth.service';
import { auth } from 'firebase/app';
//import { UsersService } from './../../../shared/services/users.service';
import { Component, OnInit } from '@angular/core';
import {  FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute , Params} from '@angular/router';
@Component({
  selector: 'app-register-simple',
  templateUrl: './simple.component.html',
  styleUrls: ['./simple.component.scss']
})
export class RegisterSimpleComponent implements OnInit {

  public show: boolean = false;
  form: FormGroup;
  registerFormSubmitted = false;
  constructor(
    private formBuilder: FormBuilder,

    private router: Router,
    private activeRoute: ActivatedRoute,
    private auth: AuthService
  ) { 
    this.buildForm();
  }

  ngOnInit() {
  }

  showPassword() {
    this.show = !this.show;
  }
  
  get rf() {
    return this.form.controls;
  }



  registerUser(event: Event){
    event.preventDefault();
    this.registerFormSubmitted = true;
    if (this.form.invalid) {
      return;
    }
    const _user = this.form.value;

    var data = { user:{
      "name": _user.name,
      "lastname": _user.lastname,
      "second_lastname": _user.second_lastname,
      "email": _user.email, 
      "password": _user.password,
      "birthday": _user.birthday,
      "objective": _user.objetivo
    }}
    this.auth.registerUser(data).subscribe((result)=>{
      localStorage.setItem('user', JSON.stringify(result));
      console.log("registro:", result);
      alert("El usuario se ha creado con éxito")
      this.router.navigate(['./auth/login']);
    });
  }



  private buildForm(): void{
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      lastname:['', [Validators.required]],
      second_lastname:['', [Validators.required]],
      email:['', [Validators.email]],
      password:['', [Validators.required]],
      birthday:['', [Validators.required]],
      objetivo:['', [Validators.required]],
      privacidad:[false, [Validators.requiredTrue]],
    });
  }

}
